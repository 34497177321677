
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import AscxServiceTerms from '@rdss-mono/ascx-service-terms';
  import axios from 'axios';
  import { getEndpoint, userLanguage, getErrorMessage } from '@/utils/Helpers';
  import eventBus from '@/utils/EventBus';

  @Component({
    components: {
      AscxServiceTerms
    }
  })
  export default class TermsOfService extends Vue {
    private termsContent = '';
    private loaded = false;

    private created() {
      this.loadTermsOfService();
    }

    @Watch('language')
    onLanguageChanged(): void {
      this.loadTermsOfService();
    }

    private get language(): string {
      return this.$store.getters.language;
    }

    public loadTermsOfService(): void {
      const loader = this.$loading.show();
      const tosUrl = `terms-of-service/${userLanguage(
        process.env.VUE_APP_LANGUAGE,
        this.language,
        this.$store.getters.user.language
      )}/terms_of_service.html`;
      axios
        .get(
          `${getEndpoint(
            process.env.VUE_APP_ENDPOINT,
            false
          )}s3/get-file?s3Path=${tosUrl}`
        )
        .then(({ data }) => {
          this.termsContent = data;
          this.loaded = true;
        })
        .catch((error) => {
          /**
           * default behavior
           */
          console.log('error', error);
        })
        .finally(() => {
          loader.hide();
        });
    }

    private get acceptedTos(): boolean {
      return this.$store.getters.user.accepted_tos;
    }

    private translate(key: string): string {
      return this.$t(key).toString();
    }

    private cancelTos() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'login' });
    }

    private acceptTos() {
      const { user } = this.$store.getters;
      this.$http
        .post(`user/${user.email_address}/tos`, {
          accepted_tos: true
        })
        .then(() => {
          this.$store.commit('updateUser', {
            ...user,
            accepted_tos: true
          });
          const session = JSON.parse(localStorage.getItem('acme-session'));
          session.user = { ...user, accepted_tos: true };
          localStorage.setItem('acme-session', JSON.stringify(session));
          this.$router.push({ name: 'Home' });
        })
        .catch((error) => {
          eventBus.$emit(
            'showToast',
            'label.server_error',
            `${getErrorMessage(error)}`,
            'danger',
            error.response.status
          );
        });
    }
  }
